var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rows books"
  }, [_c('div', {
    staticClass: "card mt-2 pt-2"
  }, [_c('h2', {
    staticClass: "title h2 text-center"
  }, [_vm._v(" Detail Reading Duration ")]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.aggregate, function (data, no) {
    return _c('div', {
      key: no,
      staticClass: "col-sm-4"
    }, [_c('b-card', {
      key: no,
      staticClass: "shadow-lg",
      attrs: {
        "no-body": true
      }
    }, [_c('b-card-body', {
      staticClass: "p-0 clearfix"
    }, [_c('i', {
      staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
    }), _vm.aggregate ? _c('div', {
      staticClass: "text-uppercase mb-0 pt-3"
    }, [_c('label', {
      attrs: {
        "for": ""
      }
    }, [_vm._v(" " + _vm._s(data.vendor_name) + " ")])]) : _c('div', {
      staticClass: "h6 text-info mb-0 pt-3"
    }, [_c('b-spinner', {
      attrs: {
        "type": "grow",
        "label": "Loading..."
      }
    })], 1), _vm.aggregate ? _c('div', {
      staticClass: "text-muted text-uppercase font-md"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(data.amount, "IDR")) + " / (" + _vm._s(data.total) + ") ")]) : _c('div', {
      staticClass: "h6 text-info mb-0 pt-3"
    }, [_c('b-spinner', {
      attrs: {
        "type": "grow",
        "label": "Loading..."
      }
    })], 1)])], 1)], 1);
  }), 0), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-auto text-black"
  }, [_vm._v("Disbursement : ")]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatPrice(_vm.amount, "IDR")) + " ")])])]), _c('table', {
    staticClass: "table table-striped table-borderless table-md table-responsive-sm"
  }, [_vm._m(0), !_vm.isLoading ? _c('tbody', [_vm._l(_vm.items, function (item, index) {
    return [_c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s((_vm.currentPage - 1) * _vm.perPage + (index + 1)))]), _c('td', [_vm._v(_vm._s(item.userbuffet_id))]), _c('td', [_vm._v(_vm._s(item.item_name))]), _c('td', [_vm._v(_vm._s(item.item_type))]), _c('td', [_vm._v(_vm._s(item.read_duration))]), _c('td', [_vm._v(_vm._s(item.ratio.toFixed(6)))]), _c('td', [_vm._v(_vm._s(item.paid_buffet_duration) + " hari")]), _c('td', [_vm._v(_vm._s(_vm.formatPrice(item.book_offer_price, "IDR")))]), _c('td', [_vm._v(_vm._s(_vm.formatPrice(item.publisher_revenue, "IDR")))]), _c('td', [_vm._v(_vm._s(item.vendor_name))])])];
  })], 2) : _c('tbody', [[_c('tr', [_c('td', {
    attrs: {
      "colspan": "9"
    }
  }, [_c('div', {
    staticClass: "text-center text-danger my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  }), _c('strong', [_vm._v(" Please Wait...")])], 1)])])]], 2)]), _c('b-row', {
    staticClass: "justify-content-start"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "bg-success"
  }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Buffet ID")]), _c('th', [_vm._v("Item Name ")]), _c('th', [_vm._v("Item Type")]), _c('th', [_vm._v("Duration")]), _c('th', [_vm._v("Ratio")]), _c('th', [_vm._v("Cuttoff")]), _c('th', [_vm._v("Price")]), _c('th', [_vm._v("Revenue")]), _c('th', [_vm._v("Vendor")])])]);
}]

export { render, staticRenderFns }