<template>
  <div class="rows books">
    <div class="card mt-2 pt-2">
      <h2 class="title h2 text-center"> Detail Reading Duration </h2>
      <div class="card-body">
        <div class="row">
          <div  class="col-sm-4" v-for="(data, no ) in aggregate" :key="no">
            <b-card :no-body="true" :key="no" class="shadow-lg">
              <b-card-body class="p-0 clearfix">
                <i
                  class="fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
                ></i>
                <div v-if="aggregate" class="text-uppercase mb-0 pt-3">
                  <label for="">
                    {{ data.vendor_name }}
                  </label>
                </div>
                <div v-else class="h6 text-info mb-0 pt-3">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>

                <div v-if="aggregate" class="text-muted text-uppercase font-md">
                  {{ formatPrice(data.amount,"IDR")}} / ({{data.total}})
                </div>
                <div v-else class="h6 text-info mb-0 pt-3">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <span class="col-sm-auto text-black">Disbursement : </span>
            <div class="col-md-auto">
              <strong>{{ formatPrice(amount,"IDR")}} </strong>  
            </div>
          </div>
          <table  class="table table-striped table-borderless table-md table-responsive-sm">
            <thead class="bg-success">
              <tr>
                <th>No</th>
                <th>Buffet ID</th>
                <th>Item Name </th>
                <th>Item Type</th>
                <th>Duration</th>
                <th>Ratio</th>
                <th>Cuttoff</th>
                <th>Price</th>
                <th>Revenue</th>
                <th>Vendor</th>
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <template v-for="(item, index) in items">
                <tr :key="item.id">
                  <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
                  <td>{{item.userbuffet_id}}</td>
                  <td>{{ item.item_name }}</td>
                  <td>{{ item.item_type }}</td>
                  <td>{{ item.read_duration }}</td>
                  <td>{{ item.ratio.toFixed(6) }}</td>
                  <td>{{ item.paid_buffet_duration }} hari</td>
                  <td>{{ formatPrice(item.book_offer_price,"IDR")}}</td>
                  <td>{{ formatPrice(item.publisher_revenue,"IDR") }}</td>
                  <td>{{ item.vendor_name }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template>
                <tr>
                  <td colspan="9">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong> Please Wait...</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
           <b-row class="justify-content-start">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapGetters, mapActions} from 'vuex'
import moment from "moment";
  export default {
    name: 'SubledgerVendor',
    data () {
      return {
        Dates: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        range: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
        currentPage: 1,
        perPage: 100,
        revenue:[],
        aggregate:[]
      }
    },
    computed: {
      ...mapGetters('subledger',['amount']),
      ...mapState({
        items: (state) => state.subledger.readings,
        isLoading: (state) => state.subledger.isLoadingDetailRevenue,
        totalRows: (state) => state.subledger.totalReadings,
        amount: (state) => state.subledger.totalRevenue,
      })
    },
    watch: {
      currentPage: function() {
        this.actionSearch()
      },
      items: function() {
        if(this.items.length > 0) {
          this.setGroupByvendor()
        }
      }
    },
    mounted() {
      this.actionSearch()
    },
    methods: {
      //
      ...mapActions('subledger',['fetchByTransaction']),
      actionSearch(){
        let trans_id = this.$route.params.trans_id
        let payload = {
          trans_id: trans_id,
          vendor_id: this.$route.params.vendor_id,
          limit: this.perPage,
          offset : this.currentPage
        };
        
        this.fetchByTransaction(payload)
      },
      setGroupByvendor() {
        let mapVendor = this.items.map((item) => item.vendor_name);
        let uniqueVendor = [...new Set(mapVendor)];
        let filter = [];
        uniqueVendor.forEach(element => {
          let a = this.items.filter(item => item.vendor_name == element)
          if (a) {
            console.log(a.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.publisher_revenue), 0))
            filter.push({
              vendor_name: element,
              amount: a.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.publisher_revenue), 0),
              total: a.length
            })
            console.log(filter,mapVendor)
          }
        });

        this.aggregate =  filter
      },
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
    }
  }
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>